<template>
  <div class="flex">
    <a-form layout="horizontal" :model="formState" :rules="rules" ref="formRefFilter">
      <a-form-item label="Estado" name="filter" class="mb-2">
        <a-select v-model:value="formState.filter">
          <a-select-option value="all">Todos</a-select-option>
          <a-select-option value="1">Activos</a-select-option>
          <a-select-option value="0">Desactivados</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Buscar" class="mb-0" name="search">
        <a-input v-model:value="formState.search" />
      </a-form-item>
    </a-form>

    <button @click="applyFilters" type="submit" class="text-center px-3">
      <div class="text-3xl text-blue-400">
        <SearchOutlined />
      </div>
      <p>Buscar</p>
    </button>

    <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
      <div
        class="text-3xl"
        :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-300']"
      >
        <ClearOutlined />
      </div>
      <p>Limpiar filtros</p>
    </button>
  </div>
</template>
<script>
import { reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["reloadFetch"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);

    const formState = reactive({
      filter: store.getters["periodo/filter"],
      search: store.getters["periodo/search"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { filter, search } = toRaw(formState);
          // console.log(filter, search);
          store.dispatch("periodo/setFilter", filter);
          store.dispatch("periodo/setSearch", search);
          emit("reloadFetch");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.filter = "all";
      formState.search = "";
      store.dispatch("periodo/cleanFilters");
      emit("reloadFetch");
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled
    };
  }
};
</script>
