export const columns = [
  {
    title: "N°",
    key: "index",
    width: "4%",
    slots: { customRender: "rangepages" }
  },
  {
    title: "ID Actividad",
    dataIndex: "idperiodo",
    key: "idperiodo"
  },
  {
    title: "Nombre",
    dataIndex: "denominacion",
    key: "denominacion"
  },
  {
    title: "Anio",
    dataIndex: "anio",
    key: "anio"
  },
  {
    title: "Mes",
    dataIndex: "idmes",
    key: "idmes"
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" }
  }
];
